
import React, { useState } from 'react'
import {
    List, Footer, Swiper, Ellipsis, Image, Tag, FloatingBubble, Modal, Dialog, NumberKeyboard, PasscodeInput, Tabs, AutoCenter, Space, NoticeBar, Steps, Empty, Divider

} from 'antd-mobile'
import { createFromIconfontCN } from '@ant-design/icons';
import { ExclamationCircleFill, CheckCircleFill } from 'antd-mobile-icons'
import { useSearchParams } from 'react-router-dom'
import { request } from "@/utils";
import './index.scss';


const { Step } = Steps


const MyIcon = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/c/font_4335219_pevu4bpfde.js', // 在 iconfont.cn 上生成
});












const Y1menu = () => {
    const [urlp] = useSearchParams()
    const [visible, setVisible] = useState(urlp.get('dw') === 'yz' ? true : false)

    const token = JSON.parse(localStorage.getItem('cx315_token'))
    const about = token.about
    const cpxq = token.json.cpxq
    const cpsm = token.json.cpsm

    const imgjsonz = token.pjson1.imgjson
    const mjson = token.pjson1.mjson




    const imgjson = imgjsonz.map((e) => (
        <Swiper.Item key={e.uid}>
            <Image src={e.url} />
        </Swiper.Item>
    ))

    const onFill = async (value) => {

        const opno = { opno: urlp.get('code') };
        const ycode = { yzcode: value };
        const values = token.resq
        const val_e = Object.assign(values, ycode, opno);


        const res = await request.post('/p_qrcode_yz', val_e)
        setVisible(false)

        Dialog.alert({
            header: (res.return_code === 'success' ? <CheckCircleFill
                    style={{
                        fontSize: 46,
                        color: '#389e0d',
                    }}
                />     :  <ExclamationCircleFill
                    style={{
                        fontSize: 46,
                        color: 'var(--adm-color-warning)',
                    }}
                />
            ),
            title: res.return_msg,
            content: res.return_body,
            confirmText: '关闭',

        })



    }


    const url = encodeURIComponent(window.location.href)
    const ddsppurl = encodeURIComponent(`http://www.ddspp.com/wx?g=pid&dw=yz&reurl=${url}`)

    const turl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${token.appid}&redirect_uri=${ddsppurl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`


    return <>
        {/* 追溯默认页面，显示基本信息，若有活动=》渲染，若有找回信息=》渲染, */}
        <Swiper >
            {imgjson}
        </Swiper>
        <FloatingBubble
            axis='xy'
            magnetic='x'
            style={{
                '--initial-position-bottom': '168px',
                '--initial-position-right': '20px',
                '--edge-distance': '60px',
                '--background': 'none',
                '--size': '80px',
                '--fontsize': '80px',
                '--border-radius': '10%'
            }}
        >

            <MyIcon type={true ? "icon-loudongyanzheng" : "icon-share-remind"} className='iconclass'
                onClick={() => {
                    urlp.get('code') !== null ? setVisible(true) : window.location.href = turl
                }}
            />
        </FloatingBubble>


        <Modal
            visible={visible}
            title={<Space direction='vertical'>
                { (token.e_json.y_num >= 1  && true)  && <NoticeBar icon={<ExclamationCircleFill style={{ color: 'var(--adm-color-warning)', }} />} content='已被验证，请严防假冒！' color='alert' />}
                <AutoCenter style={{ '--text-color': '#595959', fontSize: '16px', }}>请输入验证码</AutoCenter>
            </Space>
            }
            content={<PasscodeInput length={6} plain onFill={onFill} keyboard={<NumberKeyboard title='数字键盘' />} defaultValue='' />}
            closeOnAction
            closeOnMaskClick={true}
            onClose={() => {                setVisible(false)           }}

        />

        <Tabs defaultActiveKey='1'>
            <Tabs.Tab title='首页' key='1'>
                <div style={{
                    position: 'relative',
                }}>

                    <List style={{ '--font-size': '1em' }} key='JB01'>
                        <List.Item extra={token.resq.dbcode} key='JB011'> 单元识别码</List.Item>
                        <List.Item extra={token.resq.dbno} key='JB012'>数据批次</List.Item>
                        <List.Item extra={token.resq.dbtype === 'S' ? "最小销售单元" : "集合外包"} key='JB013'>数据类型</List.Item>

                        <List.Item description={<Ellipsis
                            direction='end'
                            content={`http://cx315.com/${token.resq.dbno}/${token.resq.dbtype}/${token.resq.dbcode}`}
                            expandText='展开'
                            collapseText='收起'
                        />} key='JB014'>
                            追溯网址
                        </List.Item>
                        <List.Item extra={<Tag
                            color='success'
                            fill='outline'
                            style={{ '--border-color': `${token.pjson1.mcv}`, '--border-radius': '3px', '--font-size': '26px' }}
                        >
                            {token.e_json.s_num}
                        </Tag>} key='JB015' > 扫描次数</List.Item>
                    </List>

                    {mjson.map((m, i) => (
                        <List style={{ '--font-size': '1em' }} key={`JT${i}`}>
                            <div style={{
                                width: '100%',
                                height: '39px',
                                background: '#02af6f',
                                backgroundImage: `linear-gradient(to right, ${token.pjson1.mcv} , #fff)`,
                                color: '#FFF',
                                lineHeight: '39px',
                                paddingLeft: '10px',

                            }}
                                key={`JBTs${i}`}
                            >

                                {m.mname}
                            </div>

                            {m.list.map((k, index) => (

                                <List.Item
                                    extra={<Ellipsis
                                        direction='end'
                                        content={k.second}
                                        expandText='展开'
                                        collapseText='收起'
                                    />}

                                    key={`JBT${i}AA${index}`}
                                >
                                    {k.first}
                                </List.Item>

                            ))}






                        </List>
                    )
                    )
                    }


                </div>






            </Tabs.Tab>
            <Tabs.Tab title='流转记录' key='2'>

                {true && <Empty
                    style={{ padding: '64px 0' }}
                    imageStyle={{ width: 128 }}
                    description='暂无数据'
                />
                }

                {false && <Steps direction='vertical'>


                    <Step status='process' title={<Space block direction='vertical'>
                        <div className='syh' style={{ color: token.pjson1.mcv }}> 生产入库</div>
                        <div className='syb' style={{ color: token.pjson1.mcv }} >生产入库 →  到达地址1</div>
                        <div className='sj'>2020-12-01 12:30</div>
                        <Divider />
                    </Space>} />
                </Steps>}



            </Tabs.Tab>

            <Tabs.Tab title='使用说明' key='3'>
                <div className='about' dangerouslySetInnerHTML={{ __html: cpsm }}></div>
            </Tabs.Tab>

            <Tabs.Tab title='产品详情' key='4'>
                <div className='about' dangerouslySetInnerHTML={{ __html: cpxq }}></div>
            </Tabs.Tab>



            <Tabs.Tab title='企业简介' key='5'>
                <div className='about' dangerouslySetInnerHTML={{ __html: about }}></div>
            </Tabs.Tab>

        </Tabs>

        <Footer
            label='没有更多了'
            content='@ 2024-2026 cx315.com All rights reserved'
            links={[
                {
                    text: '胜聪网',
                    href: 'http://www.ddspp.com/',
                },
            ]}
        ></Footer>





    </>
}

export default Y1menu;