import React from 'react'
import { Empty } from 'antd-mobile'

const Y1menu2=()=>{

    return <>
    
    {/* 流转记录=》渲染，不存在=》无记录 */}
    <Empty
          style={{ padding: '64px 0' }}
          imageStyle={{ width: 128 }}
          description='暂无数据'
        />
    </>
}

export default Y1menu2;