import React from 'react'
// import { Steps } from 'antd-mobile'
import './index.scss';

const Y1menu3=()=>{
  const token=JSON.parse(localStorage.getItem('cx315_token'))
  const html=token.about
  //console.log(token)
    return <>
    {/* 认证标识，可信勋章等企业简介 */}


            <div  className='about' dangerouslySetInnerHTML={{ __html: html }}></div>
    </>
}

export default Y1menu3;