import React from 'react'
// import { TabBar } from 'antd-mobile'
// import {
//     TravelOutline,
//     TruckOutline,
//     FillinOutline,
//     InformationCircleOutline,
// } from 'antd-mobile-icons'

import {
    Outlet,
    // useLocation,
    // useNavigate
} from 'react-router-dom'
import './index.scss';

const Y1 = () => {
    return (
       <>
       <Outlet></Outlet>
       </>
    )
}

export default Y1;