import React from 'react';
import { request } from "@/utils";
import {
    SafeArea
    // ,NoticeBar 
} from 'antd-mobile'
import { Outlet, useSearchParams } from 'react-router-dom';


const M1 = () => {
    const [tno] = useSearchParams();

    // const token = JSON.parse(localStorage.getItem('cx315_token'))


    const qrcode = tno.get('n')

    const tokenname = "sc_qrkey"

    qrcode !== null && localStorage.setItem(tokenname, qrcode) // 写入localStorage 没有n qrcode 不填充


    qrcode !== null && getau()



    async function getau() {
        const res = await request.post('/p_qrcode_res', { qrcode: qrcode })
        localStorage.setItem('qrcode_res', JSON.stringify(res))
    }


    ///请求 返回json  写入

    return <>
        <SafeArea position='top' />
        {/* {token !== null && <NoticeBar content='' icon='' style={{ '--background-color': token.pjson1.mcv }}/>} */}

        <Outlet></Outlet>

        <SafeArea position='bottom' />


    </>
}

export default M1;