import axios from "axios";

const request = axios.create({
    baseURL: 'http://api.ddspp.com:30123/v3',   
    timeout: 0
})
request.interceptors.response.use((response)=>{
    return response.data    
},(error)=>{
    return Promise.reject(error)
})


export { request }